@import 'assets/variables';
@import 'assets/reset.scss';

.app {
  font: 14px/27px $primary-font-family;
  color: $primary-text-color;
  &__background {
    min-height: calc(100vh - #{$header-height + $footer-height});
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $app-background-color;
    @media (max-width: $above-tablet-width) {
      align-items: flex-start;
    }
    @media (max-width: $mobile-width) {
      min-height: calc(100vh - #{$header-height + $footer-height-mob});
    }
  }
  &__content {
    background-color: $white-color;
    margin: 40px 80px;
    padding: 40px;
    box-shadow: $box-shadow;
    width: 1280px;
    @media (max-width: $above-tablet-width) {
      width: 100%;
    }
    @media (max-width: $tablet-width) {
      margin: 35px 40px;
      padding: 30px 35px;
    }
    @media (max-width: $mobile-width) {
      margin: 30px 20px;
      padding: 30px 25px;
    }
  }
}

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 45px;
  padding: 0 20px;
  border-radius: 4px;
  text-decoration: none;
  font-size: 14px;
  font-family: $secondary-font-family;
  font-weight: 700;
  text-transform: uppercase;
  transition: background-color .2s;
  cursor: pointer;
}

.btn-empty {
  border: 2px solid $blue-color;
  color: $blue-color;
  &:hover {
    color: $white-color;
    background-color: $blue-color;
  }
}

.btn-filled {
  background-color: $blue-color;
  border: 2px solid $blue-color;
  color: $white-color;
  &:hover {
    background-color: $btn-bgc-hover;
    border: 2px solid $btn-bgc-hover;
  }
}

.tooltip {
  font-size: 16px!important;
  font-weight: 600;
  border-radius: 4px!important;
  font-family: $primary-font-family;
  background: $dark-background-color!important;
  &:after {
    border-top-color: $dark-background-color!important
  }
}
