@import 'src/assets/variables';

$before-mobile-width: 350px;

.footer {
  display: flex;
  align-items: center;
  height: $footer-height;
  padding: 0 25px;
  background-color: $footer-background-color;
  color: $white-color;
  flex-wrap: wrap;
  @media (max-width: $mobile-width) {
    justify-content: space-between;
    padding: 20px 25px;
    height: $footer-height-mob;
  }
  @media (max-width: $before-mobile-width) {
    padding: 20px;
  }
  &__nav {
    @media (max-width: $mobile-width) {
      order: 3;
      width: 40%;
      display: flex;
      flex-direction: column;
    }
    @media (max-width: $before-mobile-width) {
      width: auto;
    }
    &-link {
      margin-right: 15px;
      @media (max-width: $mobile-width){
        margin: 5px 0 0 0;
      }
    }
  }
  &__copyright {
    opacity: 0.5;
    @media (max-width: $mobile-width) {
      order: 4;
      margin-top: auto;
    }
  }
  &__social {
    display: flex;
    margin-left: auto;
    @media (max-width: $mobile-width) {
      order: 1;
      width: 40%;
      margin-left: 0;
    }
    @media (max-width: $before-mobile-width) {
      width: auto;
    }
    &-icon {
      fill: $social-link-color;
      font-size: 20px;
      transition: fill .2s;
    }
    &-link {
      display: flex;
      align-items: center;
      margin-right: 25px;
      &:hover {
        .footer__social-icon {
          fill: $social-link-color-hover;
        }
      }
    }
  }
  &__email {
    font-weight: 500;
    font-family: $secondary-font-family;
    color: $white-color;
    text-decoration: none;
    @media (max-width: $mobile-width) {
      order: 2;
    }
  }
}
