* {
  box-sizing: border-box;
}
html, body, div, h1, h2, h3, h4, h5, h6, p, blockquote, pre, img, ins, dl, dt, dd, ol, ul, li, form, menu, label, table, tbody, tfoot, thead, tr, th, td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  background: transparent;
}
input, textarea, select, button {
  margin: 0;
  padding: 0;
}
table {
  border-collapse: collapse;
}
ol, ul, li {
  list-style: none;
}
input[type="radio"] {
  width: 13px;
  height:13px;
}
