@import 'src/assets/variables';

.preloader {
  width: 94px;
  height: 77px;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
  backface-visibility: hidden;
  &-wrap {
    padding: 196px 0;
  }
  &-text {
    text-align: center;
    font-size: 18px;
    line-height: 25px;
    color: $preloader-text-color;
  }
  &-animate-row {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 20px;
    background-color: rgba(45, 183, 183, 0.54);
    z-index: 1;
    transform: translateY(135%);
    animation: move 1.4s cubic-bezier(0.15, 0.44, 0.76, 0.64);
    animation-iteration-count: infinite;
  }
  &:before,  &:after, &-bottom-border:after, &-bottom-border:before {
    border-color: #4CC3CE;
    content: "";
    position: absolute;
    width: 19px;
    height: 16px;
    border-style: solid;
    border-width: 0px;
  }
  &:before {
    left: 0;
    top: 0;
    border-left-width: 1px;
    border-top-width: 1px;
  }
  &:after {
    right: 0;
    top: 0;
    border-right-width: 1px;
    border-top-width: 1px;
  }
  &-bottom-border:before {
    left: 0;
    bottom: 0;
    border-left-width: 1px;
    border-bottom-width: 1px;
  }
  &-bottom-border:after {
    right: 0;
    bottom: 0;
    border-right-width: 1px;
    border-bottom-width: 1px;
  }
}

@keyframes move {
  0%, 100% { transform: translateY(135%); }
  50% { transform: translateY(0%); }
  75% { transform: translateY(272%); }
}