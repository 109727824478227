@import 'assets/variables';

.error-toastr {
  width: 404px;
  box-shadow: 0px 4px 4px rgba(68, 68, 68, 0.07);
  background-color: $white-color;
  padding: 20px 25px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  border-left: 4px solid $attention-color;
  border-radius: 4px;
  @media (max-width: $mobile-width) {
    width: calc(100% - 20px);
    bottom: 10px;
    right: 10px
  }
  div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
  }
  img {
    margin-right: 15px;
  }
}
.close-toastr {
  span {
    display: flex;
    width: 15px;
    height: 15px;
    cursor: pointer;
    margin-left: 15px;
    align-items: center;
  }
}
.fadeIn {
  animation-name:fadeIn;
  animation-duration:.7s
}
.fadeOut {
  animation-name:fadeOut;
  animation-duration:.3s
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}
