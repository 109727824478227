@import 'src/assets/variables';

.header {
  display: flex;
  align-items: center;
  height: $header-height;
  padding: 0 25px;
  &__nav {
    display: flex;
    margin-right: auto;
    @media (max-width: $mobile-width) {
      display: none;
    }
    &-link {
      margin-left: 23px;
      font-size: 14px;
      font-weight: 600;
      font-family: $secondary-font-family;
      color: $preloader-text-color;
      text-decoration: none;
    }
  }
  &__btn {
    &-menu {
      display: none;
      @media (max-width: $mobile-width) {
        display: block;
        margin-left: auto;
      }
    }
    &-upload.btn {
      @media (max-width: $mobile-width) {
        display: none;
      }
    }
  }
}
