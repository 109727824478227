$app-background-color: #F8F8F8;

$border-color: #E2E2E2;

$grey-color: #777777;
$grey-color-light: #B8BFDA;
$blue-color: #2BBFCA;
$dark-background-color: #535353;
$footer-background-color: #2F3344;
$attention-color: #F76464;

$primary-text-color: #141415;
$preloader-text-color: #2B2B2C;

$white-color: white;
$btn-bgc-hover: #58ccd5;

$social-link-color: #DDDDDD;
$social-link-color-hover: #2bbfca;

$primary-font-family: 'Open Sans', sans-serif;
$secondary-font-family: 'Montserrat', sans-serif;

$tablet-width: 1200px;
$above-tablet-width: 1480px;
$mobile-width: 767px;

$box-shadow: 0 4px 4px rgba(68, 68, 68, 0.07);

$header-height: 70px;
$footer-height: 60px;
$footer-height-mob: 130px;


:export {
  greyColor: $grey-color
}
