@import 'src/assets/variables';

.btn-icon {
  background-color: white;
  border: none;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}
